import { Component, Input, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem } from 'primeng/api';
import { combineLatest, map, Observable } from 'rxjs';

import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { Product, SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';
import { AbstractTranslationComponent } from '@common/angular/translation';

import { FEATURE_NAME } from '../../+state';
import { SortGroupDetailFacade } from '../../+state/sort-group-detail/sort-group-detail.facade';
import {
  SortGroupOutcomeCopyGridListFacade
} from '../../+state/sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.facade';
import { SortGroupOutcomeDetailFacade } from '../../+state/sort-group-outcome-detail/sort-group-outcome-detail.facade';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';
import { SortGroupFormComponent } from '../sort-group-form/sort-group-form.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-form-modal',
  templateUrl: './sort-group-form-modal.component.html',
  styleUrls: ['./sort-group-form-modal.component.scss']
})
export class SortGroupFormModalComponent extends AbstractTranslationComponent {
  protected override translationNamespace = 'components.sort-group-form-modal';
  readonly translateScope = `${FEATURE_NAME}.components.sort-group-form-modal`;

  @Input() hasTagsInputs: boolean;

  display = false;
  isBulkOutput: boolean;
  loading$: Observable<boolean>;
  isLastOutcome$: Observable<boolean>;
  items: MenuItem[];
  selectedIds: string[];

  @ViewChild(SortGroupFormComponent, { static: false })
    formlyForm: SortGroupFormComponent | null;
  @Input() selectedOutcomes: SortGroupOutcomeListItemDto[];
  @Input() protocolProducts: Product[];

  constructor(
    public sortGroupOutcomeDetailFacade: SortGroupOutcomeDetailFacade,
    public sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    private sortGroupOutcomeCopyGridListFacade: SortGroupOutcomeCopyGridListFacade,
    public sortGroupDetailFacade: SortGroupDetailFacade,
    public feedlotFacade: FeedlotFacade
  ) {
    super()
    this.loading$ = this.sortGroupOutcomeDetailFacade.isLoading$;
  }

  override onTranslationInit(): void {
    this.sortGroupOutcomeDetailFacade.modal$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.display = val;
        this.formlyForm?.reset();
        this.cdr.detectChanges();
      });
    this.createItemList();
    this.isLastOutcome$ = combineLatest([
      this.sortGroupOutcomeListFacade.filteredList$,
      this.sortGroupOutcomeDetailFacade.detail$
    ]).pipe(
      map(([list, detail]) => {
        if (
          list.length > 1 &&
          detail &&
          list[list.length - 1].id == detail.id
        ) {
          return true;
        }
        return false;
      })
    );
  }

  protected override onTranslationReady(): void {
    this.createItemList();
  }

  createItemList(): void {
    this.items = [
      {
        label: this.getTranslation('copy-from'),
        command: (): void => {
          this.onCopyFrom();
        }
      },
      {
        label: this.getTranslation('copy-to'),
        command: (): void => {
          this.onCopyTo();
        }
      }
    ];
  }

  onCopyFrom(): void {
    this.sortGroupOutcomeCopyGridListFacade.showCopyFromModalFromForm(
      this.selectedIds
    );
  }

  onCopyTo(): void {
    if (!this.formlyForm?.form.valid) {
      this.formlyForm?.form.markAllAsTouched();
      return;
    }
    this.onNext(true);
  }

  onBack(): void {
    this.sortGroupOutcomeDetailFacade.back();
  }

  onNext(showCopyTo = false): void {
    const data = this.formlyForm?.getData();
    if (data) {
      this.isBulkOutput
        ? this.sortGroupOutcomeDetailFacade.bulk(data, showCopyTo)
        : this.sortGroupOutcomeDetailFacade.next(data, showCopyTo);
    }
  }

  onClose(): void {
    this.sortGroupOutcomeDetailFacade.hideModal();
  }

  setDefault(): void {
    const data = this.formlyForm?.getData();
    if (data) {
      this.sortGroupOutcomeDetailFacade.setDefault(data);
    }
  }

  applyDefault(): void {
    const data = this.formlyForm?.getData();
    if (data) {
      this.sortGroupOutcomeDetailFacade.applyDefault();
    }
  }

  clearDefault(): void {
    this.sortGroupOutcomeDetailFacade.clearDefault();
  }

  createOutput(outcome?: SortGroupOutcomeListItemDto): void {
    if (outcome == null && this.selectedOutcomes == null) {
      return;
    }
    const ids = this.selectedOutcomes.map((v) => v.id);
    this.selectedIds =
      outcome && !ids.includes(outcome.id) ? [outcome.id] : ids;
    this.isBulkOutput = this.selectedIds.length > 1;
    this.sortGroupOutcomeDetailFacade.showModal();
    if (this.selectedIds?.length === 1) {
      this.sortGroupOutcomeDetailFacade.get(this.selectedIds[0]);
    }
  }
}
