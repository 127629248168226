<ifhms-sort-group-input
  [formControl]="formControl"
  [attr.disabled]="props['disabled'] || false"
  (blur)="formControl.setValue(formControl.value?.trim())"
  [workOrderType]="props['workOrderType']"
  [isActionNewHomePen]="props['isActionNewHomePen']"
  [creationDate]="props['creationDate']"
  [workOrderId]="props['workOrderId']"
  [onModalClosed]="props['onModalClosed']"
  [required]="!!props['required']"
  [hasTagsInputs]="!props['hideTagsInput']"
  [protocolProducts]="props['protocolProducts']"
>
</ifhms-sort-group-input>
