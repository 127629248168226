import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { flatten, isEqual } from 'lodash-es';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  NEVER,
  Observable,
  of,
  pairwise,
  startWith,
  takeUntil,
  tap
} from 'rxjs';

import { FormlyTypesEnum, GRID_KEY, GridRendererCellType, SersiSelectListItem } from '@sersi/angular/formly/core';
import { DeviceConnectionStatusEnum, Product, ProductSource } from '@ifhms/models/feedlot';
import { toPromise, uniqueControlValueChange } from '@common/angular/utils';
import { AssignDosingGunFormComponent } from '@ifhms/common/angular/upc/dosing-guns';
import { getProductColorCode } from '@ifhms/feedlot/shared/core/utils';

import { getProductWithQuantity, getTotalQty } from '../../utils';
import { BaseProductItemComponent } from '../base-product-item.component';

@Component({
  selector: 'ifhms-product-item',
  templateUrl: './work-order-product-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkOrderProductItemComponent extends BaseProductItemComponent {
  translateScope = 'product-item';

  protected override readonly fieldClassName = 'work-order-product-item';

  private dosingGunConnectionStatus: DeviceConnectionStatusEnum;

  private readonly defaultSelectFieldConfig = {
    type: FormlyTypesEnum.SINGLE_SELECT,
    className: 'text-base user-product-dropdown',
    props: {
      optionsLabel: 'CODE_DESCRIPTION',
      selectedItemLabel: 'CODE',
      showClear: true,
      required: true,
      showErrInTooltip: true,
      resetInvalidSelections: false,
      valueClassName: 'text-truncate'
    }
  };

  protected override onFieldInit(field: FormlyFieldConfig): void {
    super.onFieldInit(field);
    this.handleParentFormChanges();
    this.subscribeToDosingGunConnectionStatus();
  }

  private handleParentFormChanges(): void {
    this.getSelectedProducts()
      .pipe(
        filter(() => !this.isParentFormChangeIgnored()),
        filter(() => !this.isWeightChangeIgnored()),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe((products: Product[]) => this.setExcludedItems(products));

    this.getWeightChange()
      .pipe(
        distinctUntilChanged(),
        filter(() => !this.isParentFormChangeIgnored()),
        filter(() => !this.isWeightChangeIgnored()),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe((weight) => this.setProductRecommendedQty(weight));

    this.getRecordWeightChange()
      .pipe(
        filter(() => !this.isParentFormChangeIgnored()),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe((isRecordWeight: boolean) => this.setIsFlatFlag(isRecordWeight));
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setId(),
      this.setCategoryColor(),
      this.setProductTypes(),
      this.setProducts(),
      this.setRouteDetail(),
      this.setUserProductRecommendedQty(),
      this.setUserProductQty(),
      this.setUserProductTotalQty(),
      this.setUserProductUnit(),
      this.setUserProductFlat(),
      this.setDosingGunButton(),
      this.setRowDeleteButton()
    ];
  }

  protected override resetProductQty(product: Product): Product {
    return {
      ...product,
      qty: null,
      recQty: null,
      unit: null,
      totalQty: null
    };
  }

  protected override getAverageWeight(): number {
    return this.fieldConfig.form?.root.get('averageWeight')?.value;
  }

  protected override getProductWithQuantity(product: Product, routeDetail: SersiSelectListItem | null): Product {
    const averageWeight = this.getAverageWeight();
    const productWithQty = getProductWithQuantity(product, routeDetail, averageWeight);
    return {
      ...productWithQty,
      totalQty: this.getTotalQty(productWithQty.qty)
    };
  }

  protected override async setProductRecommendedQty(weight: number = this.getAverageWeight()): Promise<void> {
    const model = this.fieldConfig?.model || {} as Product;
    const selectedRouteId = model.routeDetailId;

    if (!selectedRouteId || !this.fieldProps['isInitialized']) return;
    console.log(this.fieldProps);
    console.log(this.isWeightChangeIgnored());
    console.log(this.fieldConfig.form?.root);
    if (this.isWeightChangeIgnored()) return;

    const routeDetail = await toPromise(this.referenceDataFacade.getRouteById(selectedRouteId));
    const calcReqQty = this.getRecQtyByRoute(<SersiSelectListItem>routeDetail, weight);

    const isSameRecdQty = model.recQty === calcReqQty;
    const hasQtyChanged = model.qty !== calcReqQty;
    if (isSameRecdQty && !hasQtyChanged) return;

    // for flat products only recQty should be updated
    if (model.flat) {
      this.formValue.recQty = calcReqQty;
      this.fieldConfig.formControl?.patchValue({
        recQty: calcReqQty
      });
      return;
    }

    const updatedQty = {
      qty: calcReqQty,
      recQty: calcReqQty
    };

    // Update form value to avoid side effect checks
    this.formValue = { ...this.formValue, ...updatedQty };
    this.fieldConfig.formControl?.patchValue(updatedQty);

  }

  private setCategoryColor(): FormlyFieldConfig {
    return {
      key: 'aiCategoryCode',
      type: FormlyTypesEnum.COLOR_BOX,
      expressions: {
        'props.hexCode': () => getProductColorCode(this.fieldConfig.model, this.fieldConfig.model.productSource || ProductSource.Protocol)
      }
    };
  }

  private setProductTypes(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productTypeId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.referenceDataFacade.productTypesWithProducts$,
        readonly: this.fieldProps['disableProductTypeEdit'],
        excludeItems$: this.excludedTypeIds
      }
    };
  }

  private setProducts(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.getProductOptions(this.fieldConfig.model.productTypeId),
        excludeItems$: this.excludedProductIds
      }
    };
  }

  private setRouteDetail(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'routeDetailId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.getRouteOptions(this.fieldConfig.model.productId),
        excludeItems$: this.excludedRouteIds
      }
    };
  }

  private setUserProductRecommendedQty(): FormlyFieldConfig {
    return {
      key: 'recQty',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        className: 'text-sm font-medium'
      }
    };
  }

  private setUserProductQty(): FormlyFieldConfig {
    return {
      key: 'qty',
      type: FormlyTypesEnum.NUMBER_INPUT,
      className: 'text-base',
      props: {
        disabled: false,
        maxFractionDigits: 1,
        min: 0.1,
        showErrInTooltip: true
      },
      expressions: {
        'props.readonly': '!model.flat'
      }
    };
  }

  private setUserProductTotalQty(): FormlyFieldConfig {
    return {
      key: 'totalQty',
      defaultValue: 33,
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        className: 'text-sm font-medium',
        useExpressionVal: true
      },
      expressions: {
        'props.value': (field): number | null => this.getTotalQty(field.model.qty)
      }
    };
  }

  private setUserProductUnit(): FormlyFieldConfig {
    return {
      key: 'unit',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        className: 'text-sm font-medium'
      }
    };
  }

  private setUserProductFlat(): FormlyFieldConfig {
    return {
      key: 'flat',
      type: FormlyTypesEnum.CHECKBOX,
      props: {
        onClick: (field: FormlyFieldConfig): void => {
          const parentContext = this.getParentContext();
          const isFlat = field.model.flat;
          // recalculate qty when a product is not flat anymore
          if (!isFlat && parentContext?.flat !== isFlat) {
            void this.setProductRecommendedQty();
          }
        }
      },
      expressions: {
        'props.readonly': (): boolean => !this.fieldConfig.form?.root.get('isRecordWeight')?.value
      }
    };
  }

  private setRowDeleteButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'delete-btn',
        cellRendererParams: {
          type: GridRendererCellType.Delete
        }
      }
    };
  }

  private setDosingGunButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'dosing-gun-btn',
        onClick: () => this.handleDosingGunAction(),
        translateFn: this.fieldProps['translateFn'],
        cellRendererParams: {
          type: GridRendererCellType.DosingGun
        }
      }
    };
  }

  private getSelectedProducts(): Observable<Product[]> {
    const relatedProductKeys = this.fieldProps['relatedProductsKeys'] || [];
    return combineLatest([
      this.getProductsValueChange(this.fieldProps['contextKey']),
      ...relatedProductKeys.map((key: string) => this.getProductsValueChange(key))
    ])
      .pipe(
        map(([contextProducts, ...relatedProducts]) => {
          return [...contextProducts, ...flatten(relatedProducts)];
        }),
        tap(selectedProdcuts => this.selectedProducts = selectedProdcuts)
      );
  }

  private getProductsValueChange(controlKey: string): Observable<Product[]> {
    const contextWrapper = this.fieldConfig?.form?.root.get(controlKey);
    const formControl = contextWrapper?.get(GRID_KEY) || null;
    const products$ = formControl?.valueChanges || of([]);
    return products$.pipe(
      startWith(null, formControl?.value || []),
      pairwise(),
      filter(([prev, next]) => !isEqual(prev, next)),
      map(([, next]) => next)
    );
  }

  private getWeightChange(): Observable<number> {
    const formControl = this.fieldConfig?.form?.root.get('averageWeight');
    return formControl?.valueChanges || NEVER;
  }

  private getRecordWeightChange(): Observable<boolean> {
    const formControl = this.fieldConfig?.form?.root.get('isRecordWeight') || null;
    return uniqueControlValueChange(formControl);
  }

  private getTotalQty(qty: number | null): number | null {
    const numberOfHeads = this.fieldProps['numberOfHeads'];
    return getTotalQty(qty, numberOfHeads);
  }

  private setIsFlatFlag(isRecordWeight: boolean): void {
    this.fieldConfig.formControl?.patchValue({ flat: !isRecordWeight });
    if (isRecordWeight) {
      void this.setProductRecommendedQty();
    }
  }

  private handleDosingGunAction(): void {
    if (this.dosingGunConnectionStatus === DeviceConnectionStatusEnum.Connected) {
      const config = {
        ...this.fieldConfig?.form?.value,
        workOrderId: this.route.parent?.snapshot.params['id'],
        workOrderNumber: this.fieldConfig?.form?.root.value.workOrderNumber,
        workOrderType: this.fieldConfig?.form?.root.value.workOrderType
      };
      this.dialogService.openModal(AssignDosingGunFormComponent, {
        data: config,
        header: this.fieldProps['translateFn']('assign-dosing-gun-title')
      });
    }

    return;
  }

  private subscribeToDosingGunConnectionStatus(): void {
    this.dosingGunsFacade.dosingGunsConnectionStatus$
      .pipe(takeUntil(this.fieldDestroy$))
      .subscribe((status: DeviceConnectionStatusEnum) => {
        this.dosingGunConnectionStatus = status;
      });
  }

  private isWeightChangeIgnored(): boolean {
    const formState = this.getFormState();
    const checkTemplate = this.fieldProps['checkTemplate'];
    
    if (checkTemplate && formState['isTemplate']) {
      return true;
    }
    return false;
  }

  private isParentFormChangeIgnored(): boolean {
    const parentProductItem = this.getParentContext();
    const isRowDeleted = !parentProductItem && this.formValue.id;
    // if a product has been deleted in parent view, ignore any internal change
    return !!isRowDeleted;
  }
}
