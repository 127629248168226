import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { FeedlotPermissionsService } from '../services';

@Injectable()
export class FeedlotPermissionGuard implements CanActivate {

  constructor(
    private readonly feedlotPermissionsService: FeedlotPermissionsService,
    private readonly router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.feedlotPermissionsService.hasAnyPermission(route.data?.['permissionList'])
      .pipe(
        map(hasPermission => {
          if (hasPermission) return true;
          return this.router.parseUrl('/auth/access-denied');
        })
      )
  }

}
