import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';

import { SersiNavListItem } from '@sersi/angular/ui/side-nav';
import { AbstractTranslationService } from '@common/angular/translation';

@Injectable()
export abstract class AbstractNavigationService extends AbstractTranslationService {
  abstract getNavigationItems<T>(menuType: T): Observable<SersiNavListItem[]>;

  protected getLocalizedLabels(
    menuItems$: Observable<SersiNavListItem[]>
  ): Observable<SersiNavListItem[]> {
    this.loadTranslations();
    return menuItems$.pipe(
      switchMap((menuItems) =>
        this.translationReady$.pipe(
          map(() =>
            menuItems.map((item: SersiNavListItem) => ({
              ...item,
              label: this.getTranslation(item.key),
              subItems: item.subItems?.map((subItem) => ({
                ...subItem,
                label: this.getTranslation(subItem.key),
                subItems: subItem.subItems?.map((subSubItem) => ({
                  ...subSubItem,
                  label: this.getTranslation(subSubItem.key),
                  subItems: subSubItem.subItems?.map((subSubSubItem) => ({
                    ...subSubSubItem,
                    label: this.getTranslation(subSubSubItem.key)
                  }))
                }))
              }))
            }))
          )
        )
      )
    );
  }
}
