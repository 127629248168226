<ng-container
  *ngIf="{
    isLoading: sortGroupOutcomeDetailFacade.isLoading$ | async,
    groupDetail: sortGroupDetailFacade.detail$ | async,
    outcomeDetail: sortGroupOutcomeDetailFacade.detail$ | async,
    totalLength: sortGroupOutcomeListFacade.totalLength$ | async,
    filteredList: sortGroupOutcomeListFacade.filteredList$ | async,
    isLastOutcome: isLastOutcome$ | async,
    selectedLength: sortGroupOutcomeListFacade.selectedLength$ | async,
    feedlotId: feedlotFacade.feedlotId$ | async
  } as obs"
>
  <p-dialog
    *transloco="let t; read: translateScope"
    [(visible)]="display"
    [modal]="display"
    [style]="{ width: '70vw', height: '90vh' }"
    [closable]="true"
    [header]="
      isBulkOutput
        ? t('bulk')
        : t('outcome', {
            number: obs.outcomeDetail?.outcomeNumber || 0,
            total: obs.totalLength || 0
          })
    "
    (onHide)="onClose()"
    [attr.data-ele]="'close-icon'"
    [draggable]="false"
    [closeOnEscape]="false"
    [maximizable]="true"
    appendTo="body"
  >
    <div>
      <sersi-spin [spin]="obs.isLoading">
        <ifhms-sort-group-form
          [isBulkOutput]="isBulkOutput"
          [detail]="obs.outcomeDetail"
          [groupDetail]="obs.groupDetail"
          [operationId]="obs.feedlotId"
          [creationDate]="obs.groupDetail?.creationDate"
          [viewMode]="false"
          [visible]="display"
          [hasTagsInputs]="hasTagsInputs"
          [protocolProducts]="protocolProducts"
          class="w-full"
        ></ifhms-sort-group-form>
      </sersi-spin>
    </div>

    <ng-template pTemplate="footer">
      <ng-container *ngIf="obs.filteredList">
        <div
          class="flex gap-1 w-full mt-3 justify-content-between"
          *ngIf="!obs.isLoading"
        >
          <div class="flex gap-1 justify-content-start">
            <div>
              <p-menu
                #menu
                [model]="items"
                [popup]="true"
                appendTo="body"
              ></p-menu>
              <p-button
                [attr.data-ele]="'menu-copy-btn'"
                [label]="t('copy')"
                iconPos="right"
                (click)="menu.toggle($event)"
                class="p-button-primary w-full h-full"
                outlined="true"
                icon="pi pi-chevron-down"
                type="submit"
                [rounded]="true"
              ></p-button>
            </div>
<!--            Temporary removed as event screen doesn't support default protocol -->
<!--            <button-->
<!--              [attr.data-ele]="'set-default-btn'"-->
<!--              [label]="t('set-default')"-->
<!--              class="p-button-primary p-button-outlined"-->
<!--              icon="pi pi-file"-->
<!--              [disabled]="!formlyForm?.form?.valid"-->
<!--              *ngIf="!obs.groupDetail?.hasDefaultOutcome && !isBulkOutput"-->
<!--              (click)="setDefault()"-->
<!--              pButton-->
<!--              [rounded]="true"-->
<!--            ></button>-->
            <p-button
              [attr.data-ele]="'clear-default-btn'"
              [label]="t('clear-default')"
              severity="danger"
              outlined="true"
              icon="pi pi-file-excel"
              *ngIf="obs.groupDetail?.hasDefaultOutcome && !isBulkOutput"
              (click)="clearDefault()"
              [rounded]="true"
            ></p-button>
            <p-button
              [attr.data-ele]="'apply-default-btn'"
              [label]="t('apply-default')"
              class="p-button-primary"
              outlined="true"
              icon="pi pi-file-export"
              *ngIf="obs.groupDetail?.hasDefaultOutcome && !isBulkOutput"
              (click)="applyDefault()"
              [rounded]="true"
            ></p-button>
          </div>
          <div class="flex gap-1 justify-content-end" *ngIf="!isBulkOutput">
            <p-button
              [attr.data-ele]="'back-btn'"
              [label]="t('back')"
              [disabled]="obs.filteredList[0].id === obs.outcomeDetail?.id"
              (click)="onBack()"
              [icon]="'pi pi-arrow-left'"
              [rounded]="true"
            ></p-button>

            <p-button
              [attr.data-ele]="'next-finish-btn'"
              [label]="obs.isLastOutcome ? t('finish') : t('next')"
              (click)="onNext()"
              [disabled]="!formlyForm?.form?.valid"
              iconPos="right"
              [icon]="obs.isLastOutcome ? '' : 'pi pi-arrow-right'"
              [rounded]="true"
            ></p-button>
          </div>
          <div class="flex gap-1 justify-content-end" *ngIf="isBulkOutput">
            <p-button
              [attr.data-ele]="'apply-btn'"
              [label]="t('apply', { selected: obs.selectedLength })"
              (click)="onNext()"
              [disabled]="!formlyForm?.form?.valid"
              iconPos="right"
              [icon]="'pi pi-arrow-right'"
              [rounded]="true"
            ></p-button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </p-dialog>
</ng-container>
