import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { intersection, isEqual, pick } from 'lodash-es';
import { BehaviorSubject, concatMap, filter, firstValueFrom, from, Observable, of, takeUntil, tap } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { HashMap } from '@common/angular/interfaces';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { AhCommonDialogService, toPromise, uniqueValues, updateFieldSelectItems } from '@common/angular/utils';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { DeviceConnectionStatusEnum, DosingGunItem, Product } from '@ifhms/models/feedlot';
import { AbstractFormFieldConfigComponent, GRID_KEY, SersiSelectListItem } from '@sersi/angular/formly/core';

import { ProductValueChange, SelectedProductsMap } from '../interfaces';
import { getProductWithQuantity, getRecommendedProductQtyByRoute, PRODUCT_METADATA_KEYS } from '../utils';

@Component({ template: '' })
export abstract class BaseProductItemComponent extends AbstractFormFieldConfigComponent {

  protected formValue: Product;
  protected processingValue: Product | null;
  protected excludedTypeIds = new BehaviorSubject<string[]>([]);
  protected excludedProductIds = new BehaviorSubject<string[]>([]);
  protected excludedRouteIds = new BehaviorSubject<string[]>([]);
  protected selectedProducts: Product[] = [];

  protected get contextKey(): string {
    return this.fieldProps['contextKey'];
  }
  protected get isNavigationChange(): boolean {
    return false;
  }

  protected skipChangeEvent = false;

  constructor(
    protected referenceDataFacade: ReferenceDataFacade,
    protected dialogService: AhCommonDialogService,
    protected feedlotFacade: FeedlotFacade,
    protected route: ActivatedRoute,
    protected dosingGunsFacade: UpcDosingGunsFacade
  ) {
    super();
  }

  protected getAverageWeight?(): number;
  protected onControlUpdate?(): void;

  protected override onFieldInit(field: FormlyFieldConfig): void {
    super.onFieldInit(field);
    this.formValue = this.fieldConfig.form?.value;
    this.handleRowChanges();
  }

  protected async handleValueChangeSideEffects(rowVal: Product): Promise<ProductValueChange> {
    const productTypeChange = await this.handleProductTypeChange({ ...rowVal });
    const productChange = await this.handleProductChange(productTypeChange);
    const routeChange = await this.handleRouteChange(productChange);
    const adminLocationChange = await this.handleAdminLocationChange(routeChange);
    return {
      originalValue: rowVal,
      updatedValue: {
        ...rowVal,
        ...adminLocationChange
      }
    };
  }

  protected async setExcludedItems(selectedProducts: Product[]): Promise<void> {
    const selectedProductsMap = this.getSelectedProductsMap(selectedProducts);

    // filter out products which has no available routes
    await this.setExcludedProducts(selectedProductsMap);

    // set unavailable types, based on excluded products list
    await this.setExcludedTypes(selectedProducts);

    // update available route ids for a current product
    this.setExcludedRoutes(selectedProductsMap);
  }

  private async setExcludedProducts(selectedProductsMap: SelectedProductsMap): Promise<void> {
    const selectedProductIds = Object.keys(selectedProductsMap);
    const curentProductId = this.formValue.productId;
    const excludedProducts = [];

    // check if selected products have all available routes selected
    // and exclude them from the select options if so
    for (const productId of selectedProductIds) {
      const routeOptions = await toPromise(this.getRouteOptions(productId));
      const activeRouteOptions = routeOptions.filter(r => r.isActive);
      const { selectedRoutes } = selectedProductsMap[productId];
      const isCurrentProduct = productId === curentProductId;
      selectedProductsMap[productId].availableRoutes = activeRouteOptions.map(route => <string>route.id);
      if (selectedRoutes.length >= activeRouteOptions.length && !isCurrentProduct) {
        excludedProducts.push(productId);
      }
    }

    // update excluded product ids
    const currentExcludedProducts = this.excludedProductIds.getValue();
    if (!isEqual(excludedProducts, currentExcludedProducts)) {
      this.excludedProductIds.next(excludedProducts);
    }
  }

  private async setExcludedTypes(selectedProducts: Product[]): Promise<void> {
    const selectedTypes = selectedProducts
      .map(product => product?.productTypeId)
      .filter(typeId => !!typeId && typeId !== this.formValue?.productTypeId) as string[];
    const uniqueSelectedTypes = uniqueValues<string>(selectedTypes);
    const excludedProductIds = this.getExcludedProductsMap();
    const excludedTypes = [];

    // check if selected type has non-excluded products
    for (const typeId of uniqueSelectedTypes) {
      const productOptions = await toPromise(this.getProductOptions(typeId));
      const availableProducts = productOptions.filter(product => product.isActive && !excludedProductIds[product.id]);
      if (!availableProducts.length) {
        excludedTypes.push(typeId);
      }
    }

    // update excluded type ids if needed
    const currentExcludedTypes = this.excludedTypeIds.getValue();
    if (!isEqual(excludedTypes, currentExcludedTypes)) {
      this.excludedTypeIds.next(excludedTypes);
    }
  }

  private setExcludedRoutes(selectedProductsMap: SelectedProductsMap): void {
    const excludedRoutes = this.getExcludedRouteIds(this.formValue.productId, selectedProductsMap);
    const currentExcludedRoutes = this.excludedRouteIds.getValue();
    if (!isEqual(excludedRoutes, currentExcludedRoutes)) {
      this.excludedRouteIds.next(excludedRoutes);
    }
  }

  private getExcludedRouteIds(productId: string | null, selectedProductsMap: SelectedProductsMap): string[] {
    if (!productId) return [];
    const selectedProductRoutes = selectedProductsMap[productId]?.selectedRoutes || [];
    return selectedProductRoutes.filter(routeId => !!routeId && routeId !== this.formValue.routeDetailId) as string[];
  }

  protected getProductOptions(productTypeId: string | null): Observable<SersiSelectListItem[]> {
    return productTypeId
      ? this.referenceDataFacade.getProductsForProductTypes(productTypeId)
      : this.referenceDataFacade.productWithRoutes$;
  }

  protected getRouteOptions(productId: string | null): Observable<SersiSelectListItem[]> {
    return productId
      ? this.referenceDataFacade.getRoutesForProducts(productId)
      : of([]);
  }

  protected async handleProductTypeChange(updatedRowVal: Product): Promise<Product> {
    const newProductTypeId = updatedRowVal.productTypeId;

    // product type has not changed, exit function
    if (newProductTypeId === this.formValue.productTypeId) return updatedRowVal;

    // reset selected product if product type is cleared
    if (!newProductTypeId) {
      updatedRowVal.productId = null;
    }

    // validate is selected product still valid for the new product type
    const productOptions$ = this.getProductOptions(updatedRowVal.productTypeId);
    const productList = await toPromise(productOptions$);
    const excludedProductIds = this.excludedProductIds.getValue();
    const activeProducts = productList
      .filter(x => {
        return x.isActive && !excludedProductIds.includes(<string>x.id);
      });
    const isValidProductSelected = productList.some(product => product.id === updatedRowVal.productId);
    if (!isValidProductSelected) {
      updatedRowVal.productId = null;
    }

    // auto select product if there is only one option
    if (!updatedRowVal.productId && activeProducts.length === 1) {
      const [singleProduct] = activeProducts;
      updatedRowVal.productId = <string>singleProduct.id;
    }

    // update product options
    this.updateAvailableProductOptions(updatedRowVal.productId, updatedRowVal.productTypeId);
    this.onProductGridChange?.(updatedRowVal);

    // return all side effects for product type change
    return updatedRowVal;
  }

  protected async handleProductChange(updatedRowVal: Product): Promise<Product> {
    const newProductId = updatedRowVal.productId;

    // product has not changed, exit function
    if (newProductId === this.formValue.productId) return updatedRowVal;

    // pre-set product type by a selected product attributes
    if (newProductId) {
      const selectedProduct = await toPromise(this.referenceDataFacade.getProductById(newProductId));
      const relatedProductType = <string>selectedProduct?.attributes?.['ProductTypeId'];
      updatedRowVal.productTypeId = relatedProductType || '';
      this.updateAvailableProductOptions(updatedRowVal.productId, updatedRowVal.productTypeId);
    }

    // update route options
    const routes$ = this.getRouteOptions(newProductId);
    const routesList = await toPromise(routes$);

    const currentExcludedRoutes = this.getExcludedRouteIds(newProductId, this.getSelectedProductsMap(this.selectedProducts));

    const availableRoutes = routesList
      .filter(route => route.isActive && !currentExcludedRoutes.includes(route.id as string));
    updateFieldSelectItems(this.getFieldConfig('routeDetailId'), routes$);

    // validate is selected route still valid for the new product
    const isValidRouteSelected = routesList.some(route => route.id === updatedRowVal.routeDetailId);
    if (!isValidRouteSelected) {
      updatedRowVal.routeDetailId = null;
    }

    // auto select single route option
    if (availableRoutes.length === 1 && !updatedRowVal.routeDetailId) {
      updatedRowVal.routeDetailId = <string>availableRoutes[0].id;
    }

    return updatedRowVal;
  }

  protected setId(): FormlyFieldConfig {
    return { key: 'id' };
  }

  protected getTableFormControl(): AbstractControl<Product[]> | null {
    const contextWrapper = this.fieldConfig?.form?.root.get(this.contextKey);
    return contextWrapper?.get(GRID_KEY) || null;
  }

  protected resetProductQty(product: Product): Product {
    return {
      ...product,
      qty: null,
      recQty: null,
      unit: null
    };
  }

  protected getRecQtyByRoute(routeDetail: SersiSelectListItem, weight: number): number | null {
    const averageWeight = weight ?? 0;
    return getRecommendedProductQtyByRoute(routeDetail, averageWeight);
  }

  protected async setProductRecommendedQty(weight: number, forceQtyCheck?: boolean, isQtyAppliedViaDosingGun = false): Promise<void> {
    const selectedRouteId = this.formValue?.routeDetailId;
    if (!selectedRouteId || !this.fieldProps['isInitialized'] || this.fieldConfig.model.flat) return;
    const routeDetail = await toPromise(this.referenceDataFacade.getRouteById(selectedRouteId));
    const calcReqQty = this.getRecQtyByRoute(<SersiSelectListItem>routeDetail, weight);

    const isSameRecdQty = this.formValue.recQty === calcReqQty;
    const hasQtyChanged = !forceQtyCheck || this.fieldConfig.model.qty !== calcReqQty;
    if (isSameRecdQty && !hasQtyChanged) return;

    this.fieldConfig.formControl?.patchValue({
      recQty: calcReqQty,
      qty: isQtyAppliedViaDosingGun ? null : calcReqQty
    });

  }

  protected getProductWithQuantity(product: Product, routeDetail: SersiSelectListItem | null): Product {
    const averageWeight = this.getAverageWeight?.() ?? 0;
    return getProductWithQuantity(product, routeDetail, averageWeight);
  }

  protected getFormState(): HashMap {
    return this.fieldConfig.options?.formState || {};
  }

  protected getParentContext(): Product | null {
    const formState = this.getFormState()[this.contextKey];
    const parentKey = <string>this.fieldConfig.parent?.key ?? '';
    const parentIndex = parentKey ? +parentKey : null;
    const parentItemData = parentIndex !== null ? formState?.[parentIndex] : null;
    if (!parentItemData) return null;

    return pick(parentItemData, Object.keys(this.formValue)) as Product;
  }

  // Detect if the change comes programmatically from the parent form
  protected isParentTriggeredRerender(): boolean {
    const parentContext = this.getParentContext();
    const commonFieldKeys = intersection(Object.keys(parentContext || {}), Object.keys(this.formValue)) as (keyof Product)[];
    return commonFieldKeys
      .filter(key => !(<string[]>PRODUCT_METADATA_KEYS).includes(key))
      .some(key => {
        // fallback to null to normalize all false values before comparison ( e.g. null, undefined, '', etc )
        const parentKeyVal = parentContext?.[key] || null;
        const formKeyVal = this.formValue[key] || null;
        return parentKeyVal !== formKeyVal;
      });
  }

  protected updateControlValue(value: ProductValueChange): void {
    if (!isEqual(value.originalValue, value.updatedValue)) {
      this.fieldConfig.formControl?.patchValue({ ...value.updatedValue });
    }
  }

  private handleRowChanges(): void {
    this.fieldConfig?.formControl?.valueChanges
      .pipe(
        filter(() => !this.skipChangeEvent || this.isNavigationChange),
        filter(rowVal => {
          const isSameFormValue = isEqual(rowVal, this.formValue);
          const isAlreadyProcessing = isEqual(rowVal, this.processingValue);
          return !isSameFormValue && !isAlreadyProcessing;
        }),
        filter(() => {
          const isParentTriggeredReRender = this.isParentTriggeredRerender();
          if (isParentTriggeredReRender) this.handleParentChange();

          return !isParentTriggeredReRender;
        }),
        tap(rowVal => this.processingValue = rowVal),
        concatMap(rowVal => from(this.handleValueChangeSideEffects(rowVal))),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe((value: ProductValueChange) => {
        this.processingValue = null;
        this.formValue = { ...value.updatedValue };
        this.updateControlValue(value);
        this.onControlUpdate?.();
      });
  }

  private async handleRouteChange(updatedRowVal: Product): Promise<Product> {
    const newRouteId = updatedRowVal.routeDetailId;

    // product has not changed, exit function
    if (newRouteId === this.formValue.routeDetailId) return updatedRowVal;

    // reset QTY & unit if no route is selected
    if (!newRouteId) {
      updatedRowVal = this.resetProductQty(updatedRowVal);
    }

    // pre-set QTY & unit by a selected route attributes
    if (newRouteId) {
      const routeDetail = await toPromise(this.referenceDataFacade.getRouteById(newRouteId)) || null;
      updatedRowVal = this.getProductWithQuantity(updatedRowVal, routeDetail);
    }

    return updatedRowVal;
  }

  private async handleAdminLocationChange(updatedRowVal: Product): Promise<Product> {
    const newAdminLocationId = updatedRowVal.adminLocationId;
    const routeId = updatedRowVal.routeDetailId;

    // product has not changed, exit function.
    if (!!newAdminLocationId && newAdminLocationId === this.formValue.adminLocationId) return updatedRowVal;

    const hasAdminLocationCtrl = !!this.fieldConfig.form?.get('adminLocationId');

    if (!hasAdminLocationCtrl) return updatedRowVal;

    // default the admin location from product route details.
    if (routeId && !newAdminLocationId) {
      const routeDetail = await toPromise(this.referenceDataFacade.getRouteById(routeId));
      const recAdminLocation = routeDetail?.attributes?.['AdministrationLocation'] as string;

      if (recAdminLocation) {
        updatedRowVal.adminLocationId = recAdminLocation;
      }
    }

    return updatedRowVal;
  }

  private updateAvailableProductOptions(productId: string | null, productTypeId: string | null): void {
    const productsField = this.getFieldConfig('productId');
    const productOptions$ = this.getProductOptions(productTypeId);
    updateFieldSelectItems(productsField, productOptions$, productId);
  }

  private getSelectedProductsMap(selectedProducts: Product[]): SelectedProductsMap {
    return selectedProducts
      .filter(product => product.productId)
      .reduce((memo: SelectedProductsMap, iter: Product) => {
        const productId = iter.productId as string;
        memo[productId] ??= { ...iter, selectedRoutes: [] };
        memo[productId].selectedRoutes.push(iter.routeDetailId);
        return memo;
      }, {});
  }

  private getExcludedProductsMap(): HashMap<boolean> {
    const excludedProducts = this.excludedProductIds.getValue();
    return excludedProducts.reduce((memo: HashMap<boolean>, iter: string) => {
      memo[iter] = true;
      return memo;
    }, {});
  }

  // For the parent triggered change avoid any side effects
  // Select options should be still updated to match new values
  private handleParentChange(): void {
    this.formValue = {
      ...this.formValue,
      ...this.getParentContext()
    };

    this.rebuildSelectOptions(this.formValue);
    this.resetControlValidation();
    this.ignoreSubsequentEvents();
    this.onControlUpdate?.();
  }

  private rebuildSelectOptions(newRowVal: Product): void {
    const { productId } = newRowVal;
    const productOptions$ = this.getProductOptions(newRowVal.productTypeId);
    const routeOptions$ = this.getRouteOptions(productId);

    updateFieldSelectItems(this.getFieldConfig('productId'), productOptions$, productId);
    updateFieldSelectItems(this.getFieldConfig('routeDetailId'), routeOptions$, newRowVal.routeDetailId);
  }

  private resetControlValidation(): void {
    this.fieldConfig.formControl?.markAsPristine();
    this.fieldConfig.formControl?.markAsUntouched();
  }

  // Helper function to process row re-rendering only once
  // As model change triggers change event for every form controller in a row
  private ignoreSubsequentEvents(): void {
    this.skipChangeEvent = true;
    setTimeout(() => {
      this.skipChangeEvent = false;
    }, 10);
  }

  private async getLatestDosingGun(productId: string | null): Promise<DosingGunItem | null> {
    if (!productId) return null;
    const connectionStatus = await firstValueFrom(this.dosingGunsFacade.dosingGunsConnectionStatus$);
    if (connectionStatus !== DeviceConnectionStatusEnum.Connected) return null;
    const dosingGuns = await toPromise(this.dosingGunsFacade.dosingGunItems$);
    const connectedGun = dosingGuns?.find(dg => dg.productId === productId);
    return connectedGun || null;
  }

  protected onProductGridChange?(updatedRowVal: Product): void;

}