import { FeedlotFeaturePermission } from '../interfaces';

export const WorkOrdersPermissionList = [
  FeedlotFeaturePermission.WorkOrdersArrivalView, FeedlotFeaturePermission.WorkOrdersLotTransferView,
  FeedlotFeaturePermission.WorkOrdersMovementView, FeedlotFeaturePermission.WorkOrdersRailView,
  FeedlotFeaturePermission.WorkOrdersReHandlingView, FeedlotFeaturePermission.WorkOrdersShipmentView
];
  
export const EventsPermissionList = [
  FeedlotFeaturePermission.EventsArrivalView, FeedlotFeaturePermission.EventsPostMortemView,
  FeedlotFeaturePermission.EventsRailView, FeedlotFeaturePermission.EventsReHandlingView,
  FeedlotFeaturePermission.EventsTreatmentView
];
  
export const AnimalsPermissionList = [
  FeedlotFeaturePermission.AnimalsView
];
  
export const LotsPermissionList = [
  FeedlotFeaturePermission.LotsView
];
  
export const ReportsPermissionList = [
  FeedlotFeaturePermission.ReportsEventDetails,
  FeedlotFeaturePermission.ReportsHospitalOccupancy,
  FeedlotFeaturePermission.ReportsLotInventory,
  FeedlotFeaturePermission.ReportsPenInventory,
  FeedlotFeaturePermission.ReportsWithdrawal,
  FeedlotFeaturePermission.ReportsArrivalWorkOrder,
  FeedlotFeaturePermission.ReportsLotTransferWorkOrder,
  FeedlotFeaturePermission.ReportsMovementWorkOrder,
  FeedlotFeaturePermission.ReportsRailWorkOrder,
  FeedlotFeaturePermission.ReportsReHandlingWorkOrder,
  FeedlotFeaturePermission.ReportsShipmentWorkOrder
];
  
export const PensPermissionList = [
  FeedlotFeaturePermission.PensView
];