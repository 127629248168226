import {
  FeedlotDto,
  FeedlotSettingsGlobalDto,
  FeedlotSettingsIntegrationDto,
  FeedlotSettingsTagsDto
} from '@ifhms/models/feedlot';
import { OperationPermissionsDto } from '@ifhms/models/shared';
import { createReducer, on } from '@ngrx/store';

import { FeedlotActions } from './feedlot.actions';

export const FEEDLOT_FEATURE_NAME = 'Feedlot';

export interface State {
  feedlot: FeedlotDto | null;
  loading: boolean;
  loaded: boolean;
  isActive: boolean;
}

export const initialState: State = {
  feedlot: null,
  loading: false,
  loaded: false,
  isActive: false
};

export const feedlotReducer = createReducer(
  initialState,
  on(
    FeedlotActions.get,
    FeedlotActions.getBySlug,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    FeedlotActions.getSuccess,
    (state, action) => ({
      ...state,
      feedlot: action.feedlot,
      loading: false,
      loaded: true,
      isActive: true
    })
  ),

  on(FeedlotActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(FeedlotActions.reset, () => initialState)
);

export const getActive = (state: State): boolean => state.isActive;
export const getFeedlot = (state: State): FeedlotDto | null => state.feedlot;
export const getFeedlotId = (state: State): string => state.feedlot?.id || '';
export const getFeedlotName = (state: State): string => state.feedlot?.name || '';
export const getFeedlotPermissions = (state: State): OperationPermissionsDto | null => state.feedlot?.permissions || null;
export const getFeedlotSlug = (state: State): string => state.feedlot?.slug || '';
export const getGlobalSettings = (state: State): FeedlotSettingsGlobalDto | null => state.feedlot?.globalSettings || null;
export const getLoaded = (state: State): boolean => state.loaded;
export const getLoading = (state: State): boolean => state.loading;
export const getTagSettings = (state: State): FeedlotSettingsTagsDto | null => state.feedlot?.tagSettings || null;
export const getIntegrationSettings = (state: State): FeedlotSettingsIntegrationDto | null => state.feedlot?.integrationSettings || null;
