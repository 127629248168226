import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { FrmPermissionsDto } from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class FrmPermissionsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  get(feedlotId: string): Observable<FrmPermissionsDto> {
    return this.http.get<FrmPermissionsDto>(`${this.apiUrl}/Feedlots/${feedlotId}/Permissions`);
  }
}
