import { Injectable } from '@angular/core';
import { filter, map, Observable, ReplaySubject, tap } from 'rxjs';

import { CommonPermissionsMap } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CommonPermissionsService {
  private permissionsData$ = new ReplaySubject<CommonPermissionsMap | null>(1);
  protected loadedPermissions: CommonPermissionsMap | null;

  readonly permissions$ = this.permissionsData$.asObservable();

  setPermissions(permissions: CommonPermissionsMap | null): void {
    this.permissionsData$.next(permissions);
    this.loadedPermissions = permissions;
  }

  hasPermission(requiredPermission: string): Observable<boolean> {
    return this.permissions$.pipe(
      filter((permissions) => !!permissions),
      tap((permissions) => (this.loadedPermissions = permissions)),
      map((data) => this.hasFeaturePermission(data, requiredPermission))
    );
  }

  hasAnyPermission(permissionList: string[]): Observable<boolean> {
    return this.permissions$.pipe(
      filter((permissions) => !!permissions),
      tap((permissions) => (this.loadedPermissions = permissions)),
      map((data) => {
        return permissionList.some((permission) =>
          this.hasFeaturePermission(data, permission)
        );
      })
    );
  }

  hasAnyPermissionSync(
    permissionList: string[],
    permission?: CommonPermissionsMap | null
  ): boolean {
    const loadedPermissions = permission
      ? permission
      : this.getLoadedPermissions();
    return permissionList.some((permission) =>
      this.hasFeaturePermission(loadedPermissions, permission)
    );
  }

  // @Warning Relies on already loaded permissions
  hasPermissionSync(requiredPermission: string): boolean {
    const loadedPermissions = this.getLoadedPermissions();
    return this.hasFeaturePermission(loadedPermissions, requiredPermission);
  }

  protected getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions;
  }

  private hasFeaturePermission(
    userPermissions: CommonPermissionsMap | null,
    requiredPermission: string
  ): boolean {
    if (!userPermissions) return false;
    return userPermissions[requiredPermission];
  }
}
