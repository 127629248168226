import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, map, Observable, of, startWith, takeUntil, tap } from 'rxjs';
import { isEqual } from 'lodash-es';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { FormlyTypesEnum, GridRendererCellType } from '@sersi/angular/formly/core';
import { Product } from '@ifhms/models/feedlot';

import { BaseProductItemComponent } from '../base-product-item.component';
import { ProductValueChange } from '../../interfaces';

@Component({
  selector: 'ifhms-sort-group-product-item',
  templateUrl: './sort-group-product-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortGroupProductItemComponent extends BaseProductItemComponent {
  translateScope = 'sort-group-product-item';

  protected override readonly fieldClassName = 'event-product-item';

  private readonly defaultSelectFieldConfig = {
    type: FormlyTypesEnum.SINGLE_SELECT,
    className: 'text-base input-sm',
    props: {
      optionsLabel: 'CODE_DESCRIPTION',
      selectedItemLabel: 'CODE',
      showClear: true,
      required: true,
      showErrInTooltip: true,
      virtualScroll: false,
      resetInvalidSelections: false
    }
  }

  protected override onFieldInit(field: FormlyFieldConfig): void {
    super.onFieldInit(field);
    this.handleTableChange();
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setId(),
      this.setProductTypes(),
      this.setProducts(),
      this.setRouteDetail(),
      this.setRowDeleteButton()
    ]
  }

  protected override async handleValueChangeSideEffects(rowVal: Product): Promise<ProductValueChange> {
    const productTypeChange = await this.handleProductTypeChange({ ...rowVal });
    const productChange = await this.handleProductChange(productTypeChange);
    return {
      originalValue: rowVal,
      updatedValue: {
        ...rowVal,
        ...productTypeChange,
        ...productChange
      }
    };
  }

  // Formly fields configuration
  private setProductTypes(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productTypeId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.referenceDataFacade.productTypesWithProducts$,
        placeholder: this.getParentTranslation('type-label'),
        excludeItems$: this.excludedTypeIds
      }
    };
  }

  private setProducts(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.getProductOptions(this.fieldConfig.model.productTypeId),
        excludeItems$: this.excludedProductIds,
        placeholder: this.getParentTranslation('product-label')
      }
    };
  }

  private setRouteDetail(): FormlyFieldConfig {
    const { productId } = this.fieldConfig.model;
    return {
      ...this.defaultSelectFieldConfig,
      key: 'routeDetailId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        dropdownClassName: 'sersi-min-width-150',
        items$: this.getRouteOptions(productId),
        excludeItems$: this.excludedRouteIds,
        placeholder: this.getParentTranslation('route-label')
      }
    };
  }

  private setRowDeleteButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'delete-btn',
        cellRendererParams: {
          type: GridRendererCellType.Delete
        }
      }
    }
  }

  // Field helpers & side effect handlers
  private handleTableChange(): void {
    this.getSelectedProducts()
      .pipe(takeUntil(this.fieldDestroy$))
      .subscribe((products: Product[]) => this.setExcludedItems(products));
  }

  private getSelectedProducts(): Observable<Product[]> {
    const formControl = this.getTableFormControl();
    const products$ = formControl?.valueChanges
      .pipe(
        startWith(formControl.value || []),
        filter(products => !isEqual(products, this.selectedProducts)),
        tap(selectedProducts => {
          const protocolProducts = this.fieldProps['protocolProducts'] || [];
          this.selectedProducts = [...protocolProducts, ...selectedProducts];
        }),
        map(() => this.selectedProducts)
      );
    return products$ || of([]);
  }

}
