export enum FeedlotPermissionsReport {
  ReportsArrivalWorkOrder = 'canViewArrivalWorkOrderReport',
  ReportsEventDetails = 'canViewEventDetailsReport',
  ReportsHospitalOccupancy = 'canViewHospitalOccupancyReport',
  ReportsLotTransferWorkOrder = 'canViewLotTransferWorkOrderReport',
  ReportsMovementWorkOrder = 'canViewMovementWorkOrderReport',
  ReportsLotInventory = 'canViewLotInventoryReport',
  ReportsPenInventory = 'canViewPenInventoryReport',
  ReportsRailWorkOrder = 'canViewRailWorkOrderReport',
  ReportsReHandlingWorkOrder = 'canViewRehandlingWorkOrderReport',
  ReportsShipmentWorkOrder = 'canViewShipmentWorkOrderReport',
  ReportsWithdrawal = 'canViewWithdrawalReport'
}
